<template>

  <div class="top-background"></div>
  <div class="income-info">
    <p>余额 (元)</p>
    <p><b class="income-balance">{{balance.toFixed(2)}}</b></p>
    <div class="income-balance-nut-row">
      <nut-row>
        <nut-col :span="10">
          <div class="flex-content">
            <p>{{allBalance.toFixed(2)}}</p>
            <span>总收入 (元)</span>
          </div>
        </nut-col>
        <nut-col :span="2">
          <div class="flex-content-light">
            <p>|</p>
          </div>
        </nut-col>
        <nut-col :span="10">
          <div class="flex-content">
            <p>{{settledBalance.toFixed(2)}}</p>
            <span>已结算 (元)</span>
          </div>
        </nut-col>
      </nut-row>
    </div>
  </div>

  <div class="mine-list" v-on:click="$router.push('/notes')">
    <div class="mine-list-line mine-list-line-1">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-weibiaoti-5"></use>
      </svg>
      <span>收支明细</span>
    </div>
  </div>


  <div class="withdrawal-btn" @click="toWithdrawal">提现</div>

</template>

<script>
export default {
  name: 'Income',
  data(){
    return{
      allBalance:0.00,
      balance:0.00,
      settledBalance:0.00
    }
  },
  created(){
    this.userAccount();
  },
  methods:{
    toWithdrawal(){
      this.$router.push({path:`/withdrawal/${this.balance.toFixed(2)}`});
    },
    userAccount(){
      this.axios.post('/app/userAccount', {
          }).then((response) => {
            console.log(response);
            this.allBalance = response.allBalance
            this.balance = response.balance
            this.settledBalance = response.settledBalance
          }).catch(function (error) {
              console.log(error);
          });
    }
  }
}
</script>

<style scoped>
.icon{
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
.top-background {
width: 100%;
height: 200px;
position: relative;
z-index: -1;
overflow: hidden;
}

.top-background::after {
content: '';
width: 140%;
height: 200px;
position: absolute;
left: -20%;
top: 0;
z-index: -1;
border-radius: 0 0 50% 50%;
background: #f5f5f5;
}
.income-info{
  height: 200px;
  width: 90%;
  top: 30px;
  left: 5%;
  margin: auto;
  text-align: center;
  position: absolute;
  border-radius: 10px;
  background: #ffffff;
}
.income-info .income-balance{
  font-size: 30px;
}
.income-balance-nut-row{
  margin: 30px 0 0 0;
}
.flex-content-light{
  color: #cccccc;
}
.flex-content p{
  margin: 3px 0;
}
.flex-content span{
  font-size: 13px;
  color: #cccccc;
}
.mine-list{
  margin-top: 70px;
}
.mine-list .mine-list-line{
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  margin: 3px auto;
  background: #ffffff;
}
.mine-list .mine-list-line span{
  margin: 0 10px;
  vertical-align: middle;
}
.mine-list .mine-list-line-1{
  margin-top: 20px;
}
.withdrawal-btn{
  width: 95%;
  height: 45px;
  line-height: 45px;
  margin: 80px auto 0;
  text-align: center;
  color: #FFFFFF;
  background: #00ef83;
  border-radius: 5px;
}
</style>
